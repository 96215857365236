import { useEffect, useState } from 'react';

import {
  ERROR_AUTH,
  STORAGE_KEY_TOKEN,
  STORAGE_KEY_USER_DATA
} from '../../config';

/**
 * useAuth
 *
 * Authentication hook that fetches a token then
 * sets and returns userData to the component.
 */

const useAuth = ({ username, password, socket }) => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (!username || !password || userData?.username === username) {
      return;
    }

    socket.on('token', onToken);
    socket.on('clearToken', onClearToken);
    authenticate({ username, password });

    return () => {
      socket.off('token', onToken);
      socket.off('clearToken', onClearToken);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password]);

  /**
   * authenticate
   *
   * Authenticate with Cavela
   */

  const authenticate = async ({ username, password }) => {
    socket.emit(
      'POST:auth',
      JSON.stringify({
        username,
        password
      })
    );
  };

  /**
   * onToken
   *
   * Save Cavela auth token
   */

  const onToken = (result) => {
    const userDataResult = JSON.parse(result || '{}');

    localStorage.setItem(
      STORAGE_KEY_USER_DATA,
      JSON.stringify(userDataResult.username)
    );

    localStorage.setItem(
      STORAGE_KEY_TOKEN,
      JSON.stringify(userDataResult.token)
    );

    setUserData(userDataResult);
  };

  /**
   * onClearToken
   *
   * De-authenticate the client
   */

  const onClearToken = (pageRefresh) => {
    alert(ERROR_AUTH);
    localStorage.clear();

    if (pageRefresh) {
      window.location.reload();
    }
  };

  return [userData, setUserData];
};

export default useAuth;
