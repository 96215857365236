const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8082';
const REACT_APP_ENV = process.env.REACT_APP_ENVIRONMENT || 'test';
const IS_PREVIEW = process.env.IS_PULL_REQUEST === 'true';
const CAVELA_PHONE_NUMBER = '14158125281';
const STORAGE_KEY_USER_DATA = 'userData';
const STORAGE_KEY_TOKEN = 'token';
const MAIL_NAME = 'tom@cavela.com';
const WHATSAPP_PHONE_NUMBER_ID = '346977581833326';
const LOGIN = 'Login';
const ERROR_AUTH = 'Authentication error.';
const CAVELA_URL = process.env.REACT_APP_CAVELA_URL || 'http://localhost:8000';
const CAVELA_API_URL = `${CAVELA_URL}/api`;

const SENTRY_CONFIG = {
  dsn: 'https://9643191e8aec0f45e15a6e8bf9f652b5@o4506826568695808.ingest.us.sentry.io/4506829241057280',
  integrations: [],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
};

export {
  API_URL,
  REACT_APP_ENV,
  STORAGE_KEY_TOKEN,
  STORAGE_KEY_USER_DATA,
  MAIL_NAME,
  WHATSAPP_PHONE_NUMBER_ID,
  CAVELA_PHONE_NUMBER,
  LOGIN,
  ERROR_AUTH,
  SENTRY_CONFIG,
  IS_PREVIEW,
  CAVELA_API_URL
};
