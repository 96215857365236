import React, { useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';

const SearchBox = ({ isComposing, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  return (
    <div
      className="text-input"
      style={{
        background: isComposing ? '#3e3e3f' : ''
      }}
    >
      <BiSearchAlt2 color="#9a9a9a" />
      &nbsp;
      {!isComposing && (
        <input
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
        />
      )}
    </div>
  );
};

export default SearchBox;
