import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import Auth from './pages/Auth';
import Chat from './pages/Chat';

import { IS_PREVIEW, REACT_APP_ENV, SENTRY_CONFIG } from './config';

import './index.css';

if (REACT_APP_ENV === 'production' && !IS_PREVIEW) {
  Sentry.init(SENTRY_CONFIG);
  Sentry.setExtra('app_name', 'SupChat');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const isAuth = window.location.pathname === '/login';

root.render(
  <React.StrictMode>{isAuth ? <Auth /> : <Chat />}</React.StrictMode>
);
