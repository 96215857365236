import { useEffect, useState } from 'react';

import { io } from 'socket.io-client';

import { API_URL, LOGIN } from '../../config';
import useAuth from '../../hooks/useAuth';

import './styles.css';

const socket = io(API_URL);

const Auth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [credentials, setCredentials] = useState({});

  const [userData] = useAuth({
    ...credentials,

    socket
  });

  useEffect(() => {
    if (userData?.token) {
      window.location.href = '/';
    }

    setIsLoading(false);
  }, [userData]);

  useEffect(() => {
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  const onConnect = () => setIsConnected(true);

  const onDisconnect = () => setIsConnected(false);

  const onChangeUsername = ({ target: { value } }) => setUsername(value);

  const onChangePassword = ({ target: { value } }) => setPassword(value);

  const onClickLogin = async () => {
    setCredentials({
      username,
      password
    });
  };

  return (
    isConnected && (
      <main>
        {isLoading && (
          <div className="modal">
            <div className="loader" />
          </div>
        )}
        {!isLoading && (
          <section className="auth">
            <div className="form" role="form">
              <div className="branding">
                <img
                  src="images/supchat.png"
                  alt="SupChat"
                  width="100px"
                  height="100px"
                />
                <h2>SupChat</h2>
              </div>
              <input
                required
                placeholder="Cavela username"
                onChange={onChangeUsername}
              />
              <input
                required
                placeholder="Cavela password"
                onChange={onChangePassword}
                type="password"
              />
              <button onClick={onClickLogin}>{LOGIN}</button>
            </div>
          </section>
        )}
      </main>
    )
  );
};

export default Auth;
