import { useEffect, useRef, useState } from 'react';
import {
  BiArrowBack,
  BiChat,
  BiCheckDouble,
  BiEdit,
  BiFile,
  BiPaperclip
} from 'react-icons/bi';

import { io } from 'socket.io-client';
import { format } from 'timeago.js';

import SearchBox from '../../components/SearchBox';
import {
  API_URL,
  CAVELA_API_URL,
  CAVELA_PHONE_NUMBER,
  MAIL_NAME,
  STORAGE_KEY_TOKEN
} from '../../config';

import './styles.css';

const socket = io(API_URL);

const HANDLES = [MAIL_NAME, CAVELA_PHONE_NUMBER];

const LOADING_TIMEOUT = 400;

let _storageToken;

const Chat = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [messages, setMessages] = useState([]);
  const [threads, setThreads] = useState({});
  const [thread, setThread] = useState();
  const [isComposing, setIsComposing] = useState(false);
  const [recipient, setRecipient] = useState();
  const [recipientName, setRecipientName] = useState();
  const [contacts, setContacts] = useState([]);
  const [filteredThreads, setFilteredThreads] = useState([]);
  const [input, setInput] = useState();
  const [file, setFile] = useState(null);

  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEY_TOKEN);

    if (!token) {
      window.location.href = '/login';

      return;
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('messages', onMessages);
    socket.on('threads', onThreads);
    socket.on('error', onError);
    socket.on('messageSent', onMessageSent);
    socket.on('contactAdded', onContactAdded);
    socket.on('clearToken', onClearToken);

    _storageToken = JSON.stringify(token);
    socket.emit('GET:token', JSON.stringify({ token }));
    getThreads();
    setIsInitialLoading(false);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('messages', onMessages);
      socket.off('threads', onThreads);
      socket.off('error', onError);
      socket.off('messageSent', onMessageSent);
      socket.off('contactAdded', onContactAdded);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.onkeydown = isComposing ? onKeyDownModal : null;

    return () => (document.body.onkeydown = null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComposing]);

  useEffect(() => {
    const fetchData = async () => {
      const token = JSON.parse(localStorage.getItem(STORAGE_KEY_TOKEN));
      const res = await fetch(
        `${CAVELA_API_URL}/contacts?fuzzy_match=${recipient}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const contacts = await res.json();

      setContacts(contacts);
    };

    if (recipient) {
      fetchData(recipient);
    }
  }, [recipient]);

  useEffect(() => {
    setFilteredThreads(
      Object.values(threads).sort((a, b) => new Date(b.date) - new Date(a.date))
    );
  }, [threads]);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    }
  }, [messages]); // This effect runs whenever messages change

  const getThreads = () => {
    socket.emit(
      'GET:threads',
      JSON.stringify({
        token: _storageToken
      })
    );
  };

  const getMessages = (params) => {
    socket.emit(
      'GET:messages',
      JSON.stringify({
        token: _storageToken,

        ...params
      })
    );
  };

  const onConnect = () => setIsConnected(true);

  const onDisconnect = () => setIsConnected(false);

  const onClearToken = (pageRefresh) => {
    localStorage.clear();

    if (pageRefresh) {
      window.location.reload();
    }
  };

  const onMessages = (response) => {
    clearLoading();

    const { messages: threadMessages } = JSON.parse(response);

    if (!threadMessages) return;

    threadMessages.reverse();
    setMessages(threadMessages);
    setIsOpen(true);
  };

  const onThreads = (response) => {
    clearLoading();

    const { threads: messageThreads } = JSON.parse(response);

    if (!messageThreads) return;

    const contactId = Object.keys(messageThreads)[0];

    setThreads(messageThreads);
    setThread(contactId);
    getMessages({ id: contactId });
  };

  const onError = (response) => {
    const { status, message } = JSON.parse(response);

    alert(`Error ${status}: ${message}`);
  };

  const onMessageSent = () => {
    clearLoading();
    setInput('');
  };

  const onContactAdded = (contact) => {
    clearLoading();
    setThread(contact);
    getMessages({ id: contact });
  };

  const onClickBack = () => setIsOpen(false);

  const onClickThread = (id) => async () => {
    setInput('');
    setThread(id);
    getMessages({ id });

    // Mark messages as read
    const token = JSON.parse(localStorage.getItem(STORAGE_KEY_TOKEN));
    const unreadMessages = messages.filter((msg) => !msg.read);
    const unreadCavelaIds = unreadMessages
      .map((msg) => msg.cavelaId)
      .filter(Boolean);

    if (unreadCavelaIds.length > 0) {
      try {
        await fetch(`${CAVELA_API_URL}/communications/read`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ communication_ids: unreadCavelaIds })
        });

        // Update local state to reflect read messages
        setMessages((prevMessages) =>
          prevMessages.map((msg) => {
            return unreadCavelaIds.includes(msg.cavelaId)
              ? { ...msg, read: true }
              : msg;
          })
        );

        // Update threads to reflect read status
        setThreads((prevThreads) => ({
          ...prevThreads,
          [id]: { ...prevThreads[id], read: true }
        }));
      } catch (error) {
        console.error('Error marking communications as read:', error);
      }
    }
  };

  const onClickRecipient = () => {
    setIsComposing(true);
  };

  const onClickAttachment = async (id, filename) =>
    window.open(
      `${API_URL}/api/whatsappAttachment?mediaId=${id}&filename=${encodeURIComponent(filename)}`,
      '_blank'
    );

  const onKeyDownRecipient = ({ keyCode }) => {
    if (keyCode === 27) {
      closeModal();
    }

    if (keyCode === 13) {
      addContact();
    }
  };

  const onChangeRecipient = ({ target: { value = '' } }) => {
    setRecipient(value.trim());
  };

  const onChangeRecipientName = ({ target: { value = '' } }) => {
    setRecipientName(value);
  };

  const onKeyDownInput = ({ keyCode }) => {
    if (keyCode === 27) {
      setInput('');
    }

    if (keyCode === 13) {
      setIsLoading(true);

      const payload = {
        token: _storageToken,
        provider: thread << 0 ? 'whatsapp' : 'gmail',
        to: thread,
        text: input
      };

      if (!file) {
        socket.emit('POST:message', JSON.stringify(payload));
        setInput('');

        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const base64 = event.target.result;
        const [mimeType] = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);

        payload.cavelaFile = {
          base64,
          mimeType,
          filename: file.name
        };

        socket.emit('POST:message', JSON.stringify(payload));
      };

      reader.readAsDataURL(file);
      setInput('');
    }
  };

  const onChangeInput = ({ target: { value } }) => {
    setInput(value);
  };

  const onKeyDownModal = ({ keyCode }) => {
    if (keyCode === 27) {
      closeModal();
    }
  };

  const onClickModal = ({ target: { className } }) => {
    if (className === 'modal') {
      closeModal();
    }
  };

  const closeModal = () => {
    setIsComposing(false);
    setRecipient();
    clearLoading();
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const removeFile = () => {
    setFile(null);
  };

  const addContact = async () => {
    setIsLoading(true);

    if (Object.keys(threads).includes(recipient)) {
      onClickThread(recipient)();
      setRecipient();
      setRecipientName('');
    } else {
      const isNumeric = recipient << 0;
      socket.emit(
        'POST:contact',
        JSON.stringify({
          token: _storageToken,
          provider: isNumeric ? 'whatsapp' : 'gmail',
          handle: recipient
        })
      );
      const field = isNumeric ? 'raw_whatsapp_phone_number' : 'email';
      const payload = {
        [field]: recipient,
        name: recipientName
      };

      const token = JSON.parse(localStorage.getItem(STORAGE_KEY_TOKEN));
      const res = await fetch(`${CAVELA_API_URL}/contacts/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const contact = await res.json();

      const newThreads = {
        ...threads,
        [recipient]: {
          id: recipient,
          from: recipient,
          contact: contact[0],
          to: recipient,
          provider: isNumeric ? 'whatsapp' : 'gmail',
          preview: '',
          date: Date.now()
        }
      };

      setThreads(newThreads);
      setThread(recipient);
      onClickThread(recipient)();
    }

    setContacts([]);
    closeModal();
  };

  const clearLoading = () =>
    setTimeout(() => setIsLoading(false), LOADING_TIMEOUT);

  const handleSearch = (searchTerm) => {
    if (!searchTerm.trim()) {
      setFilteredThreads(
        Object.values(threads).sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )
      );
    } else {
      const filtered = Object.values(threads)
        .filter((thread) => {
          const contactName = `${thread.contact?.name}-${thread.id}`;
          return contactName.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      setFilteredThreads(filtered);
    }
  };

  const messageThreads = Object.values(threads);
  const threadCount = messageThreads?.length << 0;
  const contactCount = contacts?.length << 0;
  const isWhatsApp = recipient && recipient << 0;

  const ProviderLabel = () =>
    recipient?.length > 2 ? (
      <span className={`provider-label ${isWhatsApp ? 'whatsapp' : 'gmail'}`}>
        {isWhatsApp ? 'WhatsApp' : 'Gmail'}
      </span>
    ) : (
      <span className="provider-label">Provider</span>
    );

  return (
    isConnected &&
    _storageToken && (
      <main className={isOpen ? 'open' : ''}>
        {(isInitialLoading || isLoading) && (
          <div className="modal">
            <div className="loader" />
          </div>
        )}
        {!isInitialLoading && (
          <>
            {isComposing && (
              <aside className="modal" onClick={onClickModal}>
                <div className="overlay">
                  <header>
                    <h3>New conversation</h3>
                    <ProviderLabel />
                  </header>
                  <input
                    autoFocus
                    placeholder="Name"
                    onChange={onChangeRecipientName}
                    value={recipientName}
                    required={true}
                  />
                  <input
                    autoFocus
                    placeholder="Type an email, WhatsApp, or WeChat number..."
                    onKeyDown={onKeyDownRecipient}
                    onChange={onChangeRecipient}
                    value={recipient}
                    required={true}
                  />
                  {contactCount > 0 && (
                    <p>
                      Found {contactCount} matches!
                      <ul className="contact-list">
                        {contacts.map(
                          ({ name, id, raw_whatsapp_phone_number, email }) => {
                            const handle = raw_whatsapp_phone_number || email;
                            return (
                              <li className="contact-list-item" key={id}>
                                <button
                                  onClick={() => {
                                    onChangeRecipient({
                                      target: { value: handle }
                                    });
                                    onChangeRecipientName({
                                      target: { value: name }
                                    });
                                  }}
                                >
                                  {name}
                                </button>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </p>
                  )}
                  <button onClick={addContact}>Add Contact</button>
                </div>
              </aside>
            )}
            <aside className="sidebar">
              <ul className="apps">
                <li className="selected">
                  <BiChat />
                </li>
              </ul>
            </aside>
            <section>
              <div className="chats">
                <header>
                  <h2>Chats</h2>
                  <button
                    onClick={onClickRecipient}
                    className="new-conversation"
                  >
                    <BiEdit size={16} />
                    &nbsp;
                    <span>New Conversation</span>
                  </button>
                </header>
                <SearchBox isComposing={isComposing} onSearch={handleSearch} />
                <ul className="conversations">
                  {filteredThreads.map(
                    ({ id, preview, date, contact, read }) => {
                      return (
                        <li
                          key={id}
                          onClick={onClickThread(id)}
                          className={`${thread === id ? 'selected' : ''} ${!read ? 'unread' : ''}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px'
                          }}
                        >
                          <div className="profile-info">
                            <img
                              className="profile"
                              src="images/profile.png"
                              alt="profile"
                              width={50}
                              height={50}
                            />
                            <div className="name-id">
                              <h3>
                                {contact &&
                                  (Object.keys(contact).length > 0
                                    ? contact?.name
                                    : id)}
                              </h3>
                              <p className="preview">
                                <BiCheckDouble
                                  color={read ? '#34a0ff' : '#a0a0a0'}
                                  size={20}
                                />
                                <span className="preview-span">{preview}</span>
                              </p>
                            </div>
                          </div>
                          <div className="message-date">
                            <div>
                              {format(
                                new Date(new Date(date).toLocaleDateString())
                              )}
                            </div>
                            <div className="id-subtitle">
                              <h6>{id}</h6>
                            </div>
                            {!read && (
                              <span className="unread-indicator"></span>
                            )}
                          </div>
                        </li>
                      );
                    }
                  )}
                  {recipient && (
                    <li
                      onClick={onClickThread(recipient)}
                      className={thread === recipient ? 'selected' : ''}
                    >
                      <img
                        className="profile"
                        src="images/profile.png"
                        alt="profile"
                        width={40}
                        height={40}
                      />
                      <span className="detail">
                        <span className="info">
                          <h3>{recipient}</h3>
                          <h6>{format(Date.now())}</h6>
                        </span>
                        <p className="preview">
                          <span>New message</span>
                        </p>
                      </span>
                    </li>
                  )}
                </ul>
                {threadCount > 0 && (
                  <p>
                    {threadCount} message thread{threadCount === 1 ? '' : 's'}
                  </p>
                )}
              </div>
              {(threadCount > 0 || recipient) && (
                <div className="chat">
                  <header>
                    <div className="back" onClick={onClickBack}>
                      <BiArrowBack size={20} />
                    </div>
                    <img
                      className="profile"
                      src="images/profile.png"
                      alt="profile"
                      width={30}
                      height={30}
                    />
                    <span className="detail">
                      <span className="info">
                        <h3>{thread}</h3>
                      </span>
                      <p className="preview">
                        <span>
                          {messages.length > 0 &&
                            `last seen ${format(new Date(messages[0].date).toLocaleDateString())}`}
                        </span>
                      </p>
                    </span>
                  </header>
                  <div className="output-container">
                    <ul className="output">
                      <li className="divider">Start of conversation</li>
                      {messages.map(
                        (
                          {
                            from,
                            provider,
                            text = '',

                            // Gmail
                            // attachment,

                            // WhatsApp
                            document: documentAttachment,
                            image,
                            reaction,

                            date,
                            read,
                            cavelaId,
                            delivered
                          },
                          index
                        ) => {
                          const isHtml = text.trim()[0] === '<';

                          const imgSrc =
                            image &&
                            `${API_URL}/api/whatsappAttachment?mediaId=${image.id}&filename=${encodeURIComponent(image.filename)}`;

                          return (
                            <li
                              key={index}
                              ref={
                                index === messages.length - 1
                                  ? endOfMessagesRef
                                  : null
                              }
                              className={`${HANDLES.includes(from) ? 'me' : ''} ${provider === 'gmail' ? 'email' : ''}`}
                            >
                              {!read && !HANDLES.includes(from) && (
                                <span className="unread-indicator"></span>
                              )}
                              {reaction ? (
                                <span className="reaction">
                                  {reaction?.emoji}
                                </span>
                              ) : (
                                ''
                              )}
                              <span className="message">
                                {image && (
                                  <div className="attachment">
                                    <img src={imgSrc} alt="attachment" />
                                  </div>
                                )}
                                {isHtml && (
                                  <span
                                    dangerouslySetInnerHTML={{ __html: text }}
                                  />
                                )}
                                {!isHtml && text}
                                {documentAttachment && (
                                  <div className="attachment">
                                    <div
                                      className="circle"
                                      style={{ marginRight: '.5rem' }}
                                    >
                                      <BiFile size={20} />
                                    </div>
                                    &nbsp;
                                    <span
                                      onClick={() =>
                                        onClickAttachment(
                                          documentAttachment.id,
                                          documentAttachment.filename
                                        )
                                      }
                                    >
                                      {documentAttachment.filename}
                                    </span>
                                  </div>
                                )}
                              </span>
                              <span className="timestamp">
                                {format(new Date(date))}
                                {delivered && (
                                  <BiCheckDouble color="#34a0ff" size={16} />
                                )}
                              </span>
                            </li>
                          );
                        }
                      )}
                      <li className="divider">
                        {messages.length} message
                        {messages.length === 1 ? '' : 's'}
                      </li>
                    </ul>
                  </div>
                  <footer className="text-input">
                    <BiPaperclip
                      onClick={triggerFileInput}
                      size={20}
                      style={{ margin: '.5rem', cursor: 'pointer' }}
                    />
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: 'none' }}
                      onChange={handleFileUpload}
                    />
                    {file && (
                      <div
                        className="file-pill"
                        style={{
                          display: 'inline-block',
                          margin: '0 .5rem',
                          padding: '.2rem .5rem',
                          background: '3e3e3f',
                          borderRadius: '12px',
                          position: 'relative'
                        }}
                      >
                        {file.name}
                        <button
                          onClick={removeFile}
                          style={{
                            marginLeft: '.5rem',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '5px',
                            top: '5px',
                            color: '#a1a1a1'
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    )}

                    <input
                      placeholder="Type a message"
                      onKeyDown={onKeyDownInput}
                      onChange={onChangeInput}
                      value={input}
                    />
                  </footer>
                </div>
              )}
            </section>
          </>
        )}
      </main>
    )
  );
};

export default Chat;
